import React from "react"
import styled from "styled-components"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion"

import "react-accessible-accordion/dist/fancy-example.css"

import siteJSON from "../constants/sites.json"
import colors from "../utils/colors"
import Layout from "../components/layout"
import SEO from "../components/seo"
import arrowDown from "../images/svg/arrow-down.svg"
import ogImage from "../images/image_softwareentwicklerfinden.png"
import { PageSubTitle, PageTitle, PostText, Container } from "../utils/common-styles"

const PostTitle = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.DARK_BLUE};
`

const Icon = styled.img`
  margin: 0;
`

const QuestionIconContainer = styled.div`
  text-align: center;
  width: 26px;
  height: 26px;
  background: ${colors.GREEN};
  border-radius: 100%;
  margin-right: 30px;
`

const AccordionStyled = styled(Accordion)`
  border: none;
`

const AccordionButtonStyled = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: #071357;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  padding: 20px 0 20px 0;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const IndexPage = () => {
  return (
    <Layout quizType="general">
      <SEO
        title="Wo finde ich Entwickler?"
        description="Vi har samlat de bästa platserna för att hitta en utvecklare. Gå igenom vårt Quiz och få reda var du bäst hitta utvecklare."
        image={ogImage}
      />
      <Container>
        <PageTitle>
          Hier sind die 10 besten Orte, um einen Entwickler zu finden
        </PageTitle>

        {Object.entries(siteJSON).map(([key, val], index) => (
          <React.Fragment key={key}>
            <PostTitle>{`${index + 1}. ${key}`}</PostTitle>
            <PostText>
              {val.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </PostText>
          </React.Fragment>
        ))}

        <PageSubTitle>
          Wie entscheiden Sie sich für die richtige Website zur Anwerbung von Entwicklern?
        </PageSubTitle>

        <PostText>
          <p>Wichtig ist, zuerst darüber nachzudenken, wie Ihre Anforderungen aussehen und welche
            Ressourcen Sie besitzen. Sie können sich die folgenden Fragen stellen: Brauchen Sie
            einen Entwickler binnen 2 Wochen oder binnen 6 Monaten? Brauchen Sie einen Junior- oder
            Senior-Entwickler? Handelt es sich um eine Vollzeitstelle oder benötigen Sie nur
            jemanden, der in Teilzeit an Ihrem Projekt arbeitet? Haben Sie die Kompetenz, um selbst
            zu entscheiden, ob ein Entwickler gut ist oder nicht?</p>
          <p>
            Wir haben ein Quiz erstellt, das Sie durch eine Reihe von Fragen führt und die Websites
            empfiehlt, die für Sie am besten geeignet sind.
          </p>
        </PostText>

        <PageSubTitle>Häufige Fragen</PageSubTitle>

        <AccordionStyled allowMultipleExpanded={true} allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionButtonStyled>
                <QuestionIconContainer>
                  <Icon src={arrowDown} alt="" />
                </QuestionIconContainer>
                <span>Wo finde ich Entwickler?</span>
              </AccordionButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Det finns såklart inget absolut svar som gäller i alla situationer. Men det som
                påverkar kostnaden är 1) vilken programmeringspråk det rör sig om (tex är lönerna
                för Golang-utvecklare högre än lönerna för tex PHP även om det alltid finns
                undantag) 2) var i världen som personen befinner sig (om man rekryterar utvecklare
                på distans utomlands kan det bli billigare än om man bara rekryterar utvecklare i
                Sverige) och 3) hur duktig och erfaren utvecklaren är.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionButtonStyled>
                <QuestionIconContainer>
                  <Icon src={arrowDown} alt="" />
                </QuestionIconContainer>
                <span>Was kostet es, einen einzelnen Entwickler zu engagieren?</span>
              </AccordionButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Det finns många sajter och företag där du kan hitta utvecklare. Det som skiljer dem
                åt är vilka krav på din kompetens och din tid som ställs.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionButtonStyled>
                <QuestionIconContainer>
                  <Icon src={arrowDown} alt="" />
                </QuestionIconContainer>
                <span>Wie finde ich talentierte Entwickler?</span>
              </AccordionButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Om du har kompetensen att avgöra vem som är en duktig utvecklare kan du själv lägga
                ut annonser och genomföra intervjuprocessen. Om du inte har den kompetensen bör du
                vända dig till en konsultförmedlare eller en rekryteringsfirma som inriktar sig på
                utvecklare.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </AccordionStyled>
      </Container>
    </Layout>
  )
}

export default IndexPage
